<template>
    <div class="right-side shops-page">
        <h2> {{ $t('navigation.favourite') }} </h2>

        <ul class="nav nav-tabs mt-3" id="myTab" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" id="plans-tab" data-toggle="tab" href="#plans" role="tab" aria-controls="plans" aria-selected="true">
                    {{ $t('favourite.plan') }}
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="lots-tab" data-toggle="tab" href="#lots" role="tab" aria-controls="lots" aria-selected="false">
                    {{ $t('favourite.lot') }}    
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="tenders-tab" data-toggle="tab" href="#tenders" role="tab" aria-controls="tenders" aria-selected="false">
                    {{ $t('favourite.tender') }}    
                </a>
            </li>
        </ul>

        <div class="tab-content table-area" id="myTabContent">
            <div class="tab-pane fade show active" id="plans" role="tabpanel" aria-labelledby="plans-tab">
                <div class="tab__content">
                    <v-select 
                        class="style-chooser"
                        v-if="options"
                        label="name"
                        v-model="filter.plans"
                        :reduce="prefix => prefix.id"
                        placeholder="Тип метки"
                        :options="options">
                    </v-select> 
                    <button class="btn btn__search" @click="searchHandler('plans')">{{ $t('search.search_btn') }}</button>
                </div>
                <plans-table 
                    :plans="data.plans"
                    @getPlans="getPlans"
                />
            </div>
            <div class="tab-pane fade" id="lots" role="tabpanel" aria-labelledby="lots-tab">
                <div class="tab__content">
                    <v-select 
                        class="style-chooser"
                        v-if="options"
                        label="name"
                        v-model="filter.lots"
                        :reduce="prefix => prefix.id"
                        placeholder="Тип метки"
                        :options="options">
                    </v-select> 
                    <button class="btn btn__search" @click="searchHandler('lots')">{{ $t('search.search_btn') }}</button>
                </div>
                <lots-table 
                    :items="data.lots"
                    @getPlans="getLots"
                />
            </div>
            <div class="tab-pane fade" id="tenders" role="tabpanel" aria-labelledby="tenders-tab">
                <div class="tab__content">
                    <v-select 
                        class="style-chooser"
                        v-if="options"
                        label="name"
                        v-model="filter.tenders"
                        :reduce="prefix => prefix.id"
                        placeholder="Тип метки"
                        :options="options">
                    </v-select> 
                    <button class="btn btn__search" @click="searchHandler('tenders')">{{ $t('search.search_btn') }}</button>
                </div>
                <tenders-table
                    :items="data.tenders"
                    @getPlans="getTenders"
                />
            </div>
        </div>
    </div>
</template>

<script>

import PlansTable from '@/components/page/shopsTable'
import LotsTable from '@/components/lotsPage/shopsTable'
import TendersTable from '@/components/tenderPage/shopsTable'

    export default {
        name: '',
        components: {
            PlansTable,
            LotsTable,
            TendersTable
        },
        data: () => ({
            options: null,
            data: {
                plans: [],
                lots: [],
                tenders: []
            },
            filter: {
                plans: null,
                lots: null,
                tenders: null
            }
        }),
        mounted() {
            this.onShow();
        },
        methods: {
            onShow() {
                this.getTypeFavourite();
                this.getPlans();
                this.getLots();
                this.getTenders();
            },

            getPlans() {
                let params = {
                    entity: "MyFavPlan", 
                    page: 0, 
                    length: 5, 
                    filter: {}
                };

                this.$store.dispatch('GET_LIST', params)
                    .then((s) => {
                        this.data.plans = s.data.content;
                    })
                    ;
            },
            getLots() {
                let params = {
                    entity: "MyFavLot", 
                    page: 0, 
                    length: 10, 
                    filter: {}
                };

                this.$store.dispatch('GET_LIST', params)
                    .then((s) => {
                        this.data.lots = s.data.content;
                    })
                    ;
            },
            getTenders() {
                let params = {
                    "entity": "MyFavTender",
                    "page":0,
                    "length":100,
                    "filter":{}
                };

                this.$store.dispatch('GET_LIST', params)
                    .then((s) => {
                        this.data.tenders = s.data.content;
                    })
                    ;
            },
            getTypeFavourite() {
                let params = {
                    entity: "Favourite",
                    length: 100,
                    page: 0
                };
                this.$store.dispatch('GET_LIST', params) 
                    .then((res) => {
                        this.options = res.data.content;
                    })
                    ;
            },
            searchHandler(type) {
                let params = {
                    page: 0,
                    entity: '',
                    length: 100,
                    filter: {}
                };
                params.filter.type = this.filter[type];

                if (type === 'plans') {
                    params.entity = "MyFavPlan";
                }
                else
                if (type === 'lots') {
                    params.entity = "MyFavLot";
                }
                else
                if (type === 'tenders') {
                    params.entity = "MyFavTender";
                }

                this.$store.dispatch('GET_LIST', params) 
                    .then((res) => {
                        this.data[type] = res.data.content;
                    })
                    ;
            },
        }
    }
</script>

<style lang="scss" scoped>
.nav-tabs .nav-link {
    color: #553DB4;
}
.nav-tabs .nav-link.active {
    color: #495057;
}
.tab__content{
    margin: 20px 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .style-chooser {
        width: 50%;
        margin-right: 20px;
        min-width: 250px;
    }
    .btn {
        border-radius: 3px;
        @media (max-width: 420px) {
            & {
                margin-top: 20px;
            }
        }
    }
    .btn__search {
        background: #FFB217;
        color: #fff;
        outline: none;
        min-width: 100px;
    }
}
table {
    margin-top: 60px;
}

table tbody tr td {
    padding: 15px;
    &:last-child {
        text-align: right;
    }
    &:first-child {
        width: 100px !important;
        text-align: center;
    }
}
table thead tr th {
    padding: 15px;
    &:last-child {
        text-align: right;
    }
    &:first-child {
        width: 100px !important;
    }
}
</style>