<template>
    <div class="shops-modal">
        <modal
        :name="boxName"
        :width="boxWidth"
        :height="boxHeight"
        :adaptive="true"
        :scrollable="true"
        >
            <div class="text__area" v-if="tender">
                <h4 class="text-center" > {{ translateObject(tender, 'nameru', 'namekz') }} </h4>

                <p class="close" @click="hide"><i class="fas fa-times"></i></p>

                <div class="shops-modal__content">
                    <div class="row">
                        <div class="col-sm-5 col-12"> {{ $t("announce.publication_date") }} </div>
                        <div class="col-sm-7 col-12"> {{tender.startDate}} </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-5 col-12"> {{ $t("announce.source") }} </div>
                        <div class="col-sm-7 col-12"> 
                            <a target="blank" :href="getLink"> {{ translateObject(tender.system, 'nameRu', 'nameKz') }}  </a>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-5 col-12"> {{ $t("announce.num") }} </div>
                        <div class="col-sm-7 col-12"> {{ tender.externalId }} </div>
                    </div>
                    
                    <div class="row">
                        <div class="col-sm-5 col-12"> {{ $t("announce.app_begin") }} </div>
                        <div class="col-sm-7 col-12"> {{ tender.startDate }} </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-5 col-12"> {{ $t("announce.app_end") }} </div>
                        <div class="col-sm-7 col-12"> {{ tender.endDate }} </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-5 col-12"> {{ $t("announce.fio") }} </div>
                        <div class="col-sm-7 col-12"> {{ tender.customerFio }} </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-5 col-12"> {{ $t("announce.customer_iin") }} </div>
                        <div class="col-sm-7 col-12"> {{ tender.customerIin }} </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-5 col-12"> {{ $t("announce.customer_phone") }} </div>
                        <div class="col-sm-7 col-12"> {{ tender.Phone }} </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-5 col-12"> {{ $t("announce.email") }} </div>
                        <div class="col-sm-7 col-12"> {{ tender.customerEmail }} </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-5 col-12"> {{ $t("announce.organizer") }} </div>
                        <div class="col-sm-7 col-12"> {{ translateObject(tender, 'organizerNameRu', 'organizerNameKz') }} </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-5 col-12"> {{ $t("announce.hmethodtrade") }} </div>
                        <div class="col-sm-7 col-12"> {{ translateObject(tender.methodTrade, 'nameRu', 'nameKz') }} </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-5 col-12"> {{ $t("announce.sum") }} </div>
                        <div class="col-sm-7 col-12"> {{tender.summ | sum}} ₸ </div>
                    </div>
                </div>

                <button class="btn btn-close" @click="hide">{{ $t("general.close") }}</button>              
            </div>
        </modal>
    </div>
</template>

<script>
import translateMix from '@/mixins/translate'

    export default {
        name: 'tender-modal-box',
        mixins: [
            translateMix
        ],
        props: {
            boxName: {
                type: String,
                required: true
            },
            boxWidth: {
                type: Number,
                default: 1200
            },
            boxHeight: {
                default: 'auto'
            },
            tender: {
                type: Object
            }
        },
        data: () => ({
        }),
        methods: {
            show() {
                this.$modal.show(this.boxName);
            },
            hide() {
                this.$modal.hide(this.boxName)
            }
        },
        computed: {
            getLink() {
                const url = this.tender.system.tenderUrl;
                const newUrl = url && url.replace('@@@', `${this.tender.externalId}`);
                return newUrl;
            }
        },
        filters: {
            monthName(num) {
                let month = +num
                month = month - 1

                var arr=['Январь','Февраль','Март','Апрель','Май','Июнь','Июль','Август','Сентябрь','Октябрь', 'Ноябрь','Декабрь',]
                
                if(month > 12) {
                    return ''
                } else {
                    return arr[month]
                }
            }
        }
    }
</script>   

<style lang="scss" scoped>
.shops-modal {
    position: relative;
    z-index: 9999;

    &__content{
        margin: 20px 0;
        // border: 1px solid #ccc;
        border: 1px solid rgb(231, 231, 231);
        border-radius: 5px;
        .row {
            margin: 0;
            font-size: 14px;
            border-bottom: 1px solid rgb(231, 231, 231);
            &:first-child{
                a {
                    display: inline;
                    color: #553DB4;
                }
            }
            &:last-child{
                border-bottom: none;
            }
            .col-12 {
                padding: 12px 15px;
                @media(max-width: 575px) {
                    text-align: center;
                }
            }
            .col-sm-5 {
                border-right: 1px solid #ccc;
                text-align: right;
                @media(max-width: 575px) {
                    text-align: center;
                    border-right: none;
                }
            }
            &:nth-child(odd) {
                background: rgb(241, 241, 241);
            }
        }
    }
}
.text__area {
    padding: 50px;
}
.btn-close {
    background: #FFAD00;
    display: block;
    color: #fff;
    width: 300px;
    margin: 0 auto;
}
    
</style>